import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const bebenono = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Bébé-nono" />
    <h3 className='underline-title'>Bébé-nono</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        J'ai vu<br />
        <span className="par-starting"></span>une brise déraciner un baobab!<br />
        <span className="par-starting"></span>une grenouille devenir plus grosse qu'un boeuf!<br />
        <span className="par-starting"></span>un macroscélide terraser un éléphant!<br />
      </p>
      <p>
        Oui oui!<br />
        On m'a appris qu'autrefois<br />
        <span className="par-starting"></span>David fronda le géant Goliath!<br />
        <span className="par-starting"></span>Esther brisa l'épée d'Aman!<br />
      </p>
      <p>
        Je fus certain qu'à Bukavu<br />
        <span className="par-starting"></span>Bébé gagna le coeur de Nono!<br />
        <span className="par-starting"></span>Et triompa sur l'intrigue de la Thébaïde!<br />
      </p>
      <p>
        Apprenons que<br />
        <span className="par-starting"></span>L'échec le plus sensible du fort<br />
        <span className="par-starting"></span>C'est de n'avoir pas réussi à brisser<br />
        <span className="par-starting"></span>Le moral du faible en lui imposant<br />
        <span className="par-starting"></span>De très dures épreuves inhumaines<br />
      </p>
      <p>
        Et pourtant<br />
        <span className="par-starting"></span>Bébé n'avait comme armes que<br />
        <span className="par-starting"></span>- ses soupirs de résignation<br />
        <span className="par-starting"></span>- ses larmes innocentes<br />
        <span className="par-starting"></span>- son silence nazaréen<br />
      </p>
      <p>
        Bébé n'a pas vu qu'une aurore<br />
        Aux âmes innocentes tout prospère<br />
        Elle verra maintes aurores <br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default bebenono
